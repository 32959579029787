<template>
  <el-container class="content">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      <div class="center">
        单据详情
        <div v-if="pageName === '已预约'" class="text text1">
          {{ pageName }}
        </div>
        <div v-if="pageName === '服务中'" class="text text2">
          {{ pageName }}
        </div>
        <div v-if="pageName === '已关闭'" class="text text3">
          {{ pageName }}
        </div>
      </div>
      <div class="right">
        <template v-if="pageName === '已预约' || pageName === '服务中'">
          <!-- <div
            v-if="pageName === '已预约'"
            class="r-box"
            @click="jumpAbout('修改')"
          >
            修改
          </div> -->
          <!-- <div class="r-box" @click="closeOrder">关闭</div>
          <div class="r-box" @click="logOrder">打印</div>
          <div class="r-box" @click="markShow = true">备注</div> -->
        </template>
        <!-- <template v-if="pageName === '已关闭'">
          <div class="r-box" @click="delOrder">删除</div>
          <div class="r-box" @click="markShow = true">备注</div>
        </template> -->
      </div>
    </el-header>
    <el-main class="main">
      <div class="info">
        <div class="title">顾客信息</div>
        <div class="list-box">
          <div class="list">
            <div class="l-left">顾客姓名</div>
            <div class="l-right" v-if="list.user">
              {{ list.user.realname || ""
              }}<span>{{ list.user.dengji.name || "" }}</span>
            </div>
          </div>
          <div class="list">
            <div class="l-left">手机号码</div>
            <div class="l-right" v-if="list.user">
              {{ list.user.mobile }}
            </div>
          </div>
          <div class="list">
            <div class="l-left">
              <span v-if="pageName === '已预约'">开始时间</span>
              <span v-if="pageName === '服务中'">到店时间</span>
              <span v-if="pageName === '已关闭'">关闭时间</span>
            </div>
            <div class="l-right">{{ list.pretime }}</div>
          </div>
        </div>
      </div>
      <div class="info">
        <div
          class="title"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>服务项目</span>
          <el-button type="primary" size="mini" @click="tianjia()"
            >添加服务</el-button
          >
        </div>
        <div class="service-box">
          <div class="service-list" v-for="(item, i) in list.shops" :key="i">
            <!-- <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
            <div class="s-l">
              <div class="name">{{ item.server_id.name }}</div>
              <div class="time">{{ item.server_id.hour }}分钟</div>
            </div>
            <div class="s-c">x {{item.num}}</div>
            <div class="s-c">¥ {{ item.unit_price }} </div>
            <div class="s-r">
              <div class="avatar">
                <img src="@/assets/images/avatar.png" alt="" />
              </div>
              <div class="name">{{ item.server_id.person[0].realname }}</div>
              <!-- <template>
                <div
                  v-if="pageName === '已预约'"
                  class="change"
                  @click="serviceChangeShow = true"
                >
                  选择<span class="el-icon-arrow-right"></span>
                </div>
                <div v-if="pageName === '服务中'" class="change">
                  <el-tooltip
                    v-if="i !== 2"
                    class="item"
                    effect="dark"
                    content="点击提前解除技师占用"
                    placement="top-end"
                  >
                    <span class="span span1" @click="removeTakeUp"
                      >解除占用</span
                    >
                  </el-tooltip>

                  <span class="span span2" v-else>已解除占用</span>
                </div>
                <div v-if="pageName === '已关闭'" class="change"></div>
              </template> -->

              <div style="margin-left: 30px">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="lookOrderDetail(item)"
                  >删除<i></i>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="info">
        <div class="title">商品列表</div>
        <div class="service-box">
          <div class="service-list" v-for="(item, i) in 3" :key="i">
            <div class="s-l">
              <div class="name">欧诺资弹力素</div>
              <div class="time">500ml</div>
            </div>
            <div class="s-c">¥ 99.00</div>
            <div class="s-r"></div>
          </div>
        </div>
      </div> -->
      <div style="width: 100%; height: 100px"></div>
      <div class="footer">
        <!-- <div v-if="pageName === '已预约'" class="about">
          <el-button
            type="primary"
            round
            icon="el-icon-check"
            @click="userArrive"
            >顾客已到店</el-button
          >
        </div> -->
        <div v-if="pageName === '已预约'" class="service">
          <div class="left">
           <div class="left" v-if="list.shops">
              <span>共{{ list.shops.length }}项</span> <span>合计：<i>¥{{ allmoney }}</i></span> <span></span>
            </div>
          </div>
          <div class="right">
            <!-- <el-button type="primary" round @click="jumpCaseReg"
              >修改</el-button
            > -->
            <el-button type="danger" round @click="jumpPaySettle"
              >付款</el-button
            >
          </div>
        </div>
        <div v-if="pageName === '已关闭'"></div>
      </div>
    </el-main>
    <!-- 备注 -->
    <el-dialog
      class="dialog1"
      title="单据备注"
      center
      :visible.sync="markShow"
      width="30%"
      :before-close="handleClose"
    >
      <el-input v-model="markVal" placeholder="请输入单据备注"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="markShow = false">取 消</el-button>
        <el-button type="primary" @click="markShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择美容师 -->
    <el-dialog
      class="dialog"
      title="选择美容师"
      :visible.sync="serviceChangeShow"
      width="30%"
      :before-close="handleClose"
    >
      <template v-if="true">
        <div class="alert el-icon-warning">
          合理的排班会让工作更便捷、高效哦~
        </div>
        <el-container style="height: 370px">
          <el-main class="cell-list">
            <el-radio-group v-model="cellRadio">
              <el-radio
                :disabled="!i"
                :label="i"
                v-for="(item, i) in 20"
                :key="i"
                @change="changeTeacher(item)"
              >
                <div class="disable" v-if="!i">占用</div>
                <div class="title">
                  <img src="@/assets/images/avatar.png" alt="" />
                  顾小敏(美容师)
                </div>
              </el-radio>
            </el-radio-group>
          </el-main>
        </el-container>
      </template>
      <template v-else>
        <div class="empty-box">
          <div>
            <img src="@/assets/images/empty.png" alt="" />
          </div>
          <div class="text">您还没有排班哦~</div>
          <div>
            <el-button type="primary" @click="jumpScheduling"
              >立即排班</el-button
            >
          </div>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      class="dialog"
      title="选择服务"
      :visible.sync="fuwuis"
      width="30%"
   
    >
      <div class="shopName" style="margin:30px auto;">
        <span>商品名称：</span>
        <el-select
          v-model="shangpinid"
          
          filterable
          allow-create
          default-first-option
          placeholder="服务商品"
        >
          <el-option
            v-for="item in keyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- <el-input v-model="problem.shop[index].name" placeholder="商品名称" style="width: 100px;"></el-input> -->
      </div>

      <div class="shopName">
        <span>服务人员：</span>
        <el-select
          v-model="fuwuid"
          
          filterable
          allow-create
          default-first-option
          placeholder="服务人员"
        >
          <el-option
            v-for="item in shifuList"
            :key="item.id"
            :label="item.realname"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fuwuis = false">取 消</el-button>
        <el-button type="primary" @click="queding()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      pageName: "",
      markShow: false,
      markVal: "",
      serviceChangeShow: false,
      cellRadio: null,
      list: {},
      id: "",
      options: [],
      shifuList: [],
      fuwuis: false,
      fuwuid: "",
      shangpinid: "",
      keyList: [],
      allmoney:0
    };
  },
  mounted() {
    this.pageName =
      this.$route.params.pageName || window.sessionStorage.getItem("pageName");
    window.sessionStorage.setItem("pageName", this.pageName);

    this.id = this.$route.params.id || window.sessionStorage.getItem("orderid");
    window.sessionStorage.setItem("orderid", this.id);
    this.getGoodList();
  },

  methods: {
    
    // 确定
    queding() {
      this.$http
        .post("api/store/foolish/tianjiaserver", {
          orderid:  this.id,
          serverid: this.shangpinid,
          person: this.fuwuid ,
        })
        .then((res) => {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.fuwuis=false
          this.getGoodList();
        });
    },
    //获取商品服务
    getShopFuwu(item) {
      this.$http
        .post("api/store/face/serverall", {
          keyword: item,
        })
        .then((res) => {
          this.keyList = res.data.data;
        });
    },
    //获取服务人员
    getShifuList() {
      this.$http
        .post("api/store/index/shifu", {
          type: 1,
        })
        .then((res) => {
          // console.log(res);
          this.shifuList = res.data.data;
        });
    },
    // 删除服务
    lookOrderDetail(item) {
      this.$http
        .post("api/store/foolish/delorderserver", { id: item.id })
        .then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getGoodList();
        });
    },
    // 添加服务
    tianjia() {
      this.fuwuid=null
      this.shangpinid=null

      this.getShifuList();
      this.getShopFuwu();
      this.fuwuis = true;
    },

    //获取预约订单详情
    getGoodList() {
      this.$http
        .post("api/store/foolish/orderserveredit", { id: this.id })
        .then((res) => {
          console.log(res);
          this.list = res.data.data;
          let money = 0
                for(let k in this.list.shops){
                  money += Number(this.list.shops[k].unit_price)*Number(this.list.shops[k].num)
                }
                this.allmoney=money
        });
    },
    // 返回上级
    returnOn() {
      this.$router.go(-1);
    },
    jumpAbout(pageName) {
      this.$router.push({
        name: "AddReservation",
        params: {
          pageName,
        },
      });
    },
    // 修改
    jumpCaseReg() {
      this.$router.push({
        path: "/cash-reg",
      });
    },
    // 关闭
    closeOrder() {
      this.$confirm("关闭不可恢复, 确定要关闭单据吗?", "关闭确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.pageName = "已关闭";
          window.sessionStorage.setItem("pageName", this.pageName);
        })
        .catch(() => {});
    },
    // 打印
    logOrder() {
      this.$message({
        message: "打印成功",
        type: "success",
      });
    },
    // 切换美容师
    changeTeacher(item) {
      this.teacherName = item;
      this.serviceChangeShow = false;
    },
    // 顾客已到店
    userArrive() {
      this.$message({
        message: "顾客已到店!!",
        type: "success",
      });
      setTimeout(() => {
        this.pageName = "服务中";
        window.sessionStorage.setItem("pageName", this.pageName);
      }, 2000);
    },
    // 解除技师占用
    removeTakeUp() {
      this.$confirm(
        "解除占用后技师可被预约且不可恢复，确认要解除该技师吗？",
        "解除占用",
        {
          confirmButtonText: "解除占用",
          cancelButtonText: "取消",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    // 删除订单
    delOrder() {
      this.$confirm("删除不可恢复，确定要删除单据吗？", "删除确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    // 排班
    jumpScheduling() {
      this.$router.push({
        path: "/scheduling",
      });
    },
    // 付款
    jumpPaySettle() {
      if(this.list.shops.length==0){
        return this.$message({
        message: "请选择服务",
        type: "warning",
      });
      }
      this.$router.push({
        // name: "PaySettle",
        // params: {
        //       id:this.id
        //     }
        path: '/pay-fuwu',
          query: {
            type: 'sellCardList',
            id:this.id
          }
      });
    },
    // 关闭 dialog 弹窗
    handleClose() {
      this.markShow = false;
      this.serviceChangeShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  height: calc(100vh);
}

// 头部
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #333;
  height: 60px !important;
  border-bottom: 1px solid #e9e9e9;

  .left {
    color: #46a6ff;
    font-size: 24px;
  }

  .center {
    text-align: center;
    font-size: 16px;
    font-weight: bold;

    .text {
      margin-top: 5px;
      font-weight: normal;
      font-size: 12px;
    }

    .text1 {
      color: #82d588;
    }

    .text2 {
      color: #fec53d;
    }

    .text3 {
      color: #cccccc;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    color: #409eff;

    .r-box {
      padding: 0 10px;
    }
  }
}

/deep/ .main.el-main {
  background-color: #f9f9f9;
  padding: 15px !important;
  min-height: calc(100vh);

  .info {
    background-color: #fff;
    margin-bottom: 20px;
    border: 1px solid #f2f2f2;

    .title {
      border-bottom: 1px solid #f2f2f2;
      font-weight: bold;
      font-size: 14px;
      line-height: 50px;
      color: #666;
      padding: 0 10px;
    }

    .list-box {
      .list {
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        border-bottom: 1px solid #f2f2f2;
        padding: 0 10px;

        &:last-child {
          border-bottom: none;
        }

        .l-left {
          color: #666;
        }

        .l-right {
          color: #666;

          span {
            background-color: #ff9900;
            color: #fff;
            padding: 5px 15px;
            margin-left: 8px;
            border-radius: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .service-box {
      .service-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666;
        padding: 15px 10px;
        border-bottom: 1px solid #f2f2f2;

        &:last-child {
          border-bottom: none;
        }

        .s-l {
          width: 33%;
          line-height: 24px;

          .time {
            color: #999;
          }
        }

        .s-c {
          width: 33%;
          color: #f57272;
          font-weight: bold;
          text-align: center;
        }

        .s-r {
          width: 33%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .avatar {
            width: 35px;
            height: 35px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }

          .name {
            margin-left: 10px;
          }

          .change {
            margin-left: 55px;
            font-size: 12px;
            color: #999;

            .span {
              display: inline-block;
              width: 80px;
              line-height: 30px;
              text-align: center;
            }

            .span1 {
              border: 1px solid #ffb648;
              color: #ffb648;
              border-radius: 5px;
            }

            .span2 {
              color: #19be6b;
            }
          }
        }
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #fff;
  padding: 10px 0;
  border-top: 2px solid #f2f2f2;

  .about {
    .el-button {
      width: 30%;
      min-width: 240px;
    }
  }

  .service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    .left {
      color: #666;

      span {
        margin: 0 2px;
        font-size: 12px;
      }

      i {
        font-style: normal;
        color: #f56c6c;
        font-weight: bold;
      }
    }

    .right {
      .el-button {
        min-width: 160px;
      }
    }
  }
}

/deep/ .el-dialog {
  min-width: 400px;
}

.dialog {
  /deep/ .el-dialog__body {
    height: 200px;
  }

  .alert {
    color: #ff9900;
    margin-bottom: 20px;
  }

  .cell-list {
    width: 100%;

    /deep/ .el-radio-group {
      width: 100%;
      position: relative;

      .el-radio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #f2f2f2;
        padding: 10px;
        margin-bottom: 10px;
        margin-right: 0;
        position: relative;
        overflow: hidden;

        .disable {
          position: absolute;
          top: 0;
          right: -37px;
          font-size: 12px;
          transform: rotate(45deg);
          background-color: #f95e5a;
          color: #fff;
          width: 100px;
          line-height: 24px;
          text-align: center;
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
        }

        .el-radio__input {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
